<template>
  <div class="ls-page">
    <div class="ps-section__header ls-page-header">
      <h1 class="container ls-page-header-title">Request Quotation</h1>
      <h3 class="container ls-page-header-subtitle">
        For services that do not exactly fit into the services we offer. Tell us
        what you need and we will tell you how much it costs.
      </h3>
    </div>
    <div class="ps-section__content ls-page-content">
      <div class="row"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DialFrom263",
};
</script>
